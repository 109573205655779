import React, { useState, useEffect } from "react";

export default function ListaCarros(){
    
    const [carros, setCarros] = useState([]);

    useEffect(() => {
        fetch('https://59a4c6cd-f0c0-4dc9-aa62-506f17764b0f-00-4y4wes501guh.picard.replit.dev/')
        .then(res => res.json()) 
        .then(
            (resultado) => {
                setCarros(resultado)
            })
    })
    
    
    return(
        <div>
            {carros.map(
                carro => <div key = {carro.id}>{carro.id} - {carro.marca} - {carro.modelo}</div>
            )}
        </div>
    )
    
}