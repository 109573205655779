import React from "react";
import ListaCarros from "./listaCarrosFetch";

function App() {
  return (
    <>
      <ListaCarros />
    </>
  );
}

export default App;
